import emotionNormalize from 'emotion-normalize'
import { theme, styled, css } from '../../shared/theme'

export const globalStyles = css`
  ${emotionNormalize}

  *,
  *::after,
  *::before {
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }

  html {
    font-family: sans-serif;
    overflow: hidden;
  }

  html,
  body {
    height: 100%;
  }

  body {
    position: relative;
    overflow-y: scroll;
    backface-visibility: hidden;
    font-family: ${theme.fonts.sans};
    font-size: ${theme.fontSize[2]};
    font-weight: ${theme.fontWeight.medium};
    color: ${theme.colors.white};
    background-color: ${theme.colors.black};
    min-height: 100%;
  }

  *:focus {
    outline: 0;
  }
`

export const Wrapper = styled.main`
  position: relative;
`
