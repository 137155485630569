import React, { memo } from 'react'
import { Helmet } from 'react-helmet'

interface SchemaOrgProps {
  isIndex: boolean
  siteUrl: string
  title: string
  defaultTitle: string
  description: string
  author: {
    name: string
  }
  organization: {
    name: string
    logo: string
  }
  datePublished?: string
}

function SchemaOrg({
  isIndex,
  siteUrl,
  title,
  defaultTitle,
  description,
  author,
  organization,
  datePublished,
}: SchemaOrgProps) {
  const baseSchema = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      '@id': siteUrl,
      url: siteUrl,
      name: title,
      description,
      alternateName: defaultTitle,
    },
  ]

  const schema = isIndex
    ? [
        ...baseSchema,
        {
          '@context': 'http://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: [
            {
              '@type': 'ListItem',
              position: 1,
              item: {
                '@id': siteUrl,
                name: title,
                image: organization.logo,
              },
            },
          ],
        },
        {
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          url: siteUrl,
          name: title,
          alternateName: defaultTitle,
          headline: title,
          image: {
            '@type': 'ImageObject',
            url: organization.logo,
          },
          description,
          author: {
            '@type': 'Person',
            name: author.name,
          },
          publisher: {
            '@type': 'Organization',
            url: siteUrl,
            logo: organization.logo,
            name: organization.name,
          },
          mainEntityOfPage: {
            '@type': 'WebSite',
            '@id': siteUrl,
          },
          datePublished,
        },
      ]
    : baseSchema

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  )
}

export default memo(SchemaOrg)
