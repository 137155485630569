import * as React from 'react'
import { Helmet } from 'react-helmet'
import { useSiteMetaData } from '../../hooks/useSiteMetadata'
import SchemaOrg from './SchemaOrg'

export interface SEOProps {
  isIndex?: boolean
  lang?: string
  title: string
  description?: string
  meta?: any
}

export function SEO({
  isIndex = false,
  lang = 'en_US',
  title,
  description = '',
  meta = [],
}: SEOProps) {
  const siteMetadata = useSiteMetaData()
  const metaDescription = description || siteMetadata.description
  const datePublished = siteMetadata.buildTime
  const { siteUrl, author, social, organization } = siteMetadata

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        titleTemplate={`%s | ${siteMetadata.title}`}
        defer={false}
        meta={[
          {
            rel: 'canonical',
            href: siteUrl,
          },
          {
            name: 'description',
            content: metaDescription,
          },
          {
            property: 'og:url',
            content: siteUrl,
          },
          {
            property: 'og:locale',
            content: lang,
          },
          {
            property: 'og:site_name',
            content: title,
          },
          {
            property: 'og:title',
            content: title,
          },
          {
            property: 'og:description',
            content: metaDescription,
          },
          {
            property: 'og:type',
            content: 'website',
          },
          {
            property: 'og:image',
            content: organization.logo,
          },
          {
            name: 'twitter:site',
            content: social.twitter,
          },
          {
            name: 'twitter:card',
            content: 'summary',
          },
          {
            name: 'twitter:creator',
            content: author.name,
          },
          {
            name: 'twitter:title',
            content: title,
          },
          {
            name: 'twitter:description',
            content: metaDescription,
          },
          {
            name: 'apple-mobile-web-app-title',
            content: siteMetadata.title,
          },
          {
            name: 'application-name',
            content: siteMetadata.title,
          },
        ].concat(meta)}
      />
      <SchemaOrg
        isIndex={isIndex}
        siteUrl={siteUrl}
        title={title}
        defaultTitle={siteMetadata.title}
        description={metaDescription}
        author={author}
        organization={organization}
        datePublished={datePublished}
      />
    </>
  )
}
